export default {
    placeManage: '需求管理',
    placeOrder: '需求订单',
    placeOrderDetails: '订单详情',
    project: '项目管理',
    projectDetails: '项目详情',

    
    assetLaunchManage: '冰柜投放',
    storeSelection: '门店圈选',
    putReceipt: '投放管理',
    putReceiptDetails: '投放详情',
    inspection: '巡检管理',    
    inspectionDetails: '巡检详情',
    transferReceipt: '转投管理',
    transferReceiptDetails: '转投详情',
    withdrawReceipt: '撤柜管理',
    withdrawReceiptDetails: '撤柜详情',
    lostReceipt: '丢失管理',
    lostReceiptDetails: '丢失详情',
    specialReport: '特殊报备',
    specialReportDetails: '报备详情',
    exchangeReceipt: '转让管理',
    exchangeReceiptDetails: '转让详情',
    pendingRedemption: '待兑付清单',
    bpRedemption: '经销商兑付清单',
    budgetManage: '冰柜预算',

    assetAfterSalesManage: '冰柜售后',
    repairReceipt: '维修管理',
    repairReceiptDetails: '维修详情',
    scrapReceipt: '报废管理',
    scrapReceiptDetails: '报废详情',

    stockTakingManage: '盘点库存',
    inventory: '入库管理',
    inventoryDetails: '入库详情',
    inventoryTask: '盘点任务',
    inventoryTaskDetails: '盘点任务详情',
    inventoryTracking: '盘点追踪',

    masterDataManage: '主数据管理',
    partnerMaterialsManage: '经销商资料',
    suppliersManage: '供应商管理',
    modelsManage: '资产型号',
    coolerList: '资产列表',
    configManage: '配置管理',
    customer: '客户管理',
    fieldTemplate: '字段模版',
    organization: '组织架构',
    employee: '员工',
    dictionary: '字典',
    region: '行政区划',

    financialManage: '财务管理',
    depositManage: '担保金账户',
    depositDetails: '担保金详情',
    AssetRedemptionList: '冰柜兑付清单',

    logs: '日志管理',
    logsLogin: '登录日志',
    logsAction: '业务日志',
    logsCooler: '资产日志',

    noticeCenter: '通知中心',
    taskCenter: '任务中心',

    permissionManage: '权限管理',
    appManage: '应用管理',
    appCreate: '新建应用',
    appEdit: '编辑应用',

    distributeManage: '分发管理',
    distributeTasks: '分发任务',
    distributeLogs: '分发日志'

}
