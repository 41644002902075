import bizUtilsService from '@/utils/bizUtilsService';
// 菜单配置
// headerMenuConfig：头部导航配置
// asideMenuConfig：侧边导航配置
// routes: 页面路由
// 路由配置规则：What you see is What you get
const routes = {
  '/master-data-manage': { name: 'masterDataManage', showAsMenu: true, icon: 'master-data-manage', defaultOpen: true },
  '/master-data-manage/customer': {
    name: 'customer',
    showAsMenu: true,
    component: () => import(/* webpackChunkName: "customer" */ '@/pages/customer'),
  },
  '/master-data-manage/customer/field-template': {
    name: 'fieldTemplate',
    showAsMenu: false,
    component: () => import(/* webpackChunkName: "field-template" */ '@/pages/customer-field-template'),
  },
  '/master-data-manage/organization': {
    name: 'organization',
    showAsMenu: true,
    component: () => import(/* webpackChunkName: "organization" */ '@/pages/organization'),
  },
  '/master-data-manage/employee': {
    name: 'employee',
    showAsMenu: true,
    component: () => import(/* webpackChunkName: "employee" */ '@/pages/employee'),
  },
  '/master-data-manage/dictionary': {
    name: 'dictionary',
    showAsMenu: true,
    component: () => import(/* webpackChunkName: "dictionary" */ '@/pages/dictionary'),
  },
  '/master-data-manage/region': {
    name: 'region',
    showAsMenu: true,
    component: () => import(/* webpackChunkName: "region" */ '@/pages/region'),
  },
  '/permission-manage': { name: 'permissionManage', icon: 'permission-manage', showAsMenu: true, defaultOpen: true },
  '/permission-manage/app-manage': {
    name: 'appManage',
    showAsMenu: true,
    component: () => import(/* webpackChunkName: "appManage" */ '@/pages/app-manage'),
  },
  '/permission-manage/app-manage/create': {
    name: 'appCreate',
    showAsMenu: false,
    component: () => import(/* webpackChunkName: "appEdit" */ '@/pages/app-edit'),
  },
  '/permission-manage/app-manage/edit': {
    name: 'appEdit',
    showAsMenu: false,
    component: () => import(/* webpackChunkName: "appEdit" */ '@/pages/app-edit'),
  },
  '/distribute-manage': { name: 'distributeManage', icon: 'distribute-manage', showAsMenu: true, defaultOpen: true },

  '/distribute-manage/tasks': {
    name: 'distributeTasks',
    showAsMenu: false,
    component: () => import(/* webpackChunkName: "distributeTasks" */ '@/pages/distribute-tasks'),
  },
  '/distribute-manage/logs': {
    name: 'distributeLogs',
    showAsMenu: false,
    component: () => import(/* webpackChunkName: "distributeLogs" */ '@/pages/distribute-logs'),
  },
  '/task-center': {
    name: 'taskCenter',
    showAsMenu: false,
    component: () => import(/* webpackChunkName: "taskCenter" */ '@/pages/task-center'),
  },
  '/no-permission': {
    name: 'noPermission',
    showAsMenu: false,
    component: () => import(/* webpackChunkName: "noPermission" */ '@/pages/no-permission'),
  },
};

const headerMenuConfig = [];
const asideMenuConfig = bizUtilsService.routesCompose(routes);

export { asideMenuConfig, headerMenuConfig };
